import "./Header.css";
import React, { useState } from "react";
import { equipmentName } from "../../constants/arrayAboutGoods.jsx";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <button className="burger-menu" onClick={handleMenuToggle}>
        ☰
      </button>
      <ul className={`header__items ${isMenuOpen ? "open" : ""}`}>
        {equipmentName.map((item, index) => (
          <li key={index} className="header__item">
            <a href={`#${item}`} className="header__link">
              {item}
            </a>
          </li>
        ))}
      </ul>
    </header>
  );
}

export default Header;
