import "./LinkSocialMedia.css";
import React from "react";

function LinkSocialMedia({ className, src, alt, href }) {
  return (
    <li>
      <a href={href} target="_blank" rel="noreferrer">
        <img src={src} alt={alt} className={className} />
      </a>
    </li>
  );
}

export default LinkSocialMedia;
