import "./Goods.css";
import React from "react";
import footerImg from "../../images/images/goodsFooter.png";
import logo from "../../images/images/logo_m.svg";

function Goods({ title, link, price, array, claccNameImg, id }) {
  return (
    <div id={id}>
      <img src={logo} className="goods__logo" alt="Логотип"></img>
      <section className="goods">
        <h1 className="goods__title">{title}</h1>
        <img className={`goods__img ${claccNameImg}`} src={link} alt="Good" />
        <ul className="goods__items">
          {array.map((item, index) => (
            <li key={index} className="goods__item">
              {item}
            </li>
          ))}
        </ul>
        <div className="goods__price">
          <h2 className="goods__priceTitle">розничная цена:</h2>
          <p className="goods__priceValue">{price}</p>
        </div>
      </section>
      <img className="goods__footer" src={footerImg} alt=""></img>
    </div>
  );
}

export default Goods;
