import React from "react";

import Goods from "../Goods/Goods";
import "./Main.css";
// import logo from "../../assets/images/logo_m.svg";
import pharmacyPouch from "../../images/images/АптечкаМультикам.png";
import weaponBelt from "../../images/images/РеменьМультикам.png";
import magazinePouch from "../../images/images/АКх2Мультикам.png";
import resetPouch from "../../images/images/СбросыМультикам.png";
import grenadePouch from "../../images/images/ГранатныеМультикам.png";
import radioPouch from "../../images/images/РaцииМультикам.png";
import tacticalBelt from "../../images/images/ПоясМультикам.png";
import strapsBelt from "../../images/images/ЛямкиМультикам.png";
import pyatitochka from "../../images/images/ПятиточкаМультикам.png";

import {
  medicalKitFeatures,
  weaponSlingFeatures,
  tacticalVestFeatures,
  ammoPouchFeatures,
  grenadePouchFeatures,
  versatilePouchFeatures,
  tacticalPantsFeatures,
  tacticalBootsFeatures,
  tacticalHolsterFeatures,
} from "../../constants/arrayAboutGoods.jsx";
import About from "../About/About.jsx";

function Main() {
  return (
    <div className="main">
      <Goods
        title="Аптечный подсумок"
        link={pharmacyPouch}
        price="2990 р"
        array={medicalKitFeatures}
        id="Аптечный подсумок"
      />
      <Goods
        title="Оружейный ремень"
        link={weaponBelt}
        price="1600 р"
        array={weaponSlingFeatures}
        claccNameImg="goods__imgWeaponBelt"
        id="Оружейный ремень"
      />
      <Goods
        title="Подсумок для магазинов АК"
        link={magazinePouch}
        price="1860 р"
        array={tacticalVestFeatures}
        id="Подсумок для магазинов АК"
      />
      <Goods
        title="Подсумок для сброса"
        link={resetPouch}
        price="2110 р"
        array={ammoPouchFeatures}
        id="Подсумок для сброса"
      />
      <Goods
        title="Подсумок для гранаты"
        link={grenadePouch}
        price="1600 р"
        array={grenadePouchFeatures}
        id="Подсумок для гранаты"
      />

      <Goods
        title="Подсумок для радиостанции"
        link={radioPouch}
        price="1600 р"
        array={versatilePouchFeatures}
        id="Подсумок для радиостанции"
      />

      <Goods
        title="Тактический пояс"
        link={tacticalBelt}
        price="5950 р"
        array={tacticalPantsFeatures}
        id="Тактический пояс"
      />
      <Goods
        title="Лямки для пояса"
        link={strapsBelt}
        price="970 р"
        array={tacticalBootsFeatures}
        id="Лямки для пояса"
      />
      <Goods
        title="Пятиточка"
        link={pyatitochka}
        price="3270 р"
        array={tacticalHolsterFeatures}
        id="Пятиточка"
      />
      <About></About>
    </div>
  );
}

export default Main;
