const equipmentName = [
  "Аптечный подсумок",
  "Оружейный ремень",
  "Подсумок для магазинов АК",
  "Подсумок для сброса",
  "Подсумок для гранаты",
  "Подсумок для радиостанции",
  "Тактический пояс",
  "Лямки для пояса",
];

const medicalKitFeatures = [
  "Подсумок для медикаментов второго эшелона",
  "Быстрое отсоединение от велкро-основы",
  "Быстрое открытие одной рукой",
  "Вмещает набор первой помощи средних габаритов",
  "Расцветки: мох, мультикам, олива",
  "Материал: Кордон прайм с влагозащитой",
  "Полноценная ИК ремиссия",
];

const weaponSlingFeatures = [
  "Быстрая смена между одно- и двухточечным креплениями",
  "Быстрое натяжение и ослабление ремня одной рукой",
  "Универсальное и бесшумное крепление к оружию",
  "Расцветки: мох, мультикам, олива",
  "Стропа ИТГФ",
  "Полноценная ИК ремиссия",
];

const tacticalVestFeatures = [
  "Вмещает 2 магазина АК/РПК",
  "Вмещает радиостанцию типа «АЗАРТ»",
  "Регулируемая высота клапана",
  "Расцветки: мох, мультикам, олива",
  "Материал: Кордон прайм с влагозащитой",
  "Полноценная ИК ремиссия",
];

const ammoPouchFeatures = [
  "Вмещает до 12 магазинов АК",
  "Жёсткая горловина",
  "Компактно складывается",
  "Может быть надет как на ремень, так и в систему молле",
  "Расцветки: мох, мультикам, олива",
  "Материал: Кордон прайм с влагозащитой",
  "Полноценная ИК ремиссия",
];

const grenadePouchFeatures = [
  "Регулируемый по высоте клапан позволяет вместить РГД-5, Ф-1, М18 и схожие по габаритам гранаты",
  "Возможно использование в качестве подсумка под радиостанцию Baofeng UV-5R",
  "Расцветки: мох, мультикам, олива",
  "Материал: Кордон прайм с влагозащитой",
  "Полноценная ИК ремиссия",
];

const versatilePouchFeatures = [
  "Регулируемый по высоте клапан позволяет вместить Baofeng UV-82, UV-5R как со стандартным, так и с увеличенным аккумулятором",
  "Возможно использование в качестве подсумка под магазин AK или AR-15",
  "Отлично вмещает жгут или турникет",
  "Расцветки: мох, мультикам, олива",
  "Материал: Кордон прайм с влагозащитой",
  "Полноценная ИК ремиссия",
];

const tacticalPantsFeatures = [
  "Три ряда по 20 ячеек молле",
  "Отдельный карман для баллистики",
  "Съёмный внутренний брючный ремень на велкро",
  "Расцветки: мох, мультикам, олива",
  "Материал: Кордон прайм с влагозащитой",
  "Полноценная ИК ремиссия",
];

const tacticalBootsFeatures = [
  "Удобны для ношения под плитником или бронежилетом",
  "Регулировка по росту вплоть до 200 см",
  "Не мешают носке дополнительного снаряжения",
  "Расцветки: мох, мультикам, олива",
  "Стропа ИТГФ",
  "Полноценная ИК ремиссия",
];

const tacticalHolsterFeatures = [
  "Карманы для баллистических пакетов",
  "Откидная спинка",
  "Удобное крепление на поясе",
  "Возможность крепления на ремень",
  "Расцветки: мох, мультикам, олива",
  "Материал: Кордон прайм с влагозащитой",
  "Полноценная ИК ремиссия",
];

export {
  medicalKitFeatures,
  weaponSlingFeatures,
  tacticalVestFeatures,
  ammoPouchFeatures,
  grenadePouchFeatures,
  versatilePouchFeatures,
  tacticalPantsFeatures,
  tacticalBootsFeatures,
  tacticalHolsterFeatures,
  equipmentName,
};
