import "./About.css";
import React from "react";
import logo from "../../images/images/logo_m.svg";
import telegram from "../../images/icons/telegram.svg";
import vk from "../../images/icons/vk-1.svg";
import avito from "../../images/icons/лого2.png";
import LinkSocialMedia from "../UI/LinkSocialMedia/LinkSocialMedia";

function About() {
  return (
    <section className="about">
      <img src={logo} className="about__logo" alt="Логотип"></img>
      <p className="about__contactText">г. КАЛИНИНГРАД, ул. УРАЛЬСКАЯ 9</p>
      <div className="about__contact">
        <p className="about__contactText">+7 (908) 290-25-70</p>
        <ul className="about__links">
          <LinkSocialMedia
            className="linkIcon"
            alt="vk"
            src={vk}
            href="https://vk.com/muromec39"
          />
          <LinkSocialMedia
            className="linkIcon"
            alt="avito"
            src={avito}
            href="https://www.avito.ru/user/78e334daf8d016f07e32dabcfd33f0c7/profile/all?src=search_seller_info&sellerId=78e334daf8d016f07e32dabcfd33f0c7"
          />
          <LinkSocialMedia
            className="linkIcon"
            alt="telegram"
            src={telegram}
            href="https://t.me/muromec_39"
          />
        </ul>
      </div>
      <p className="about__contactText">contact@muromets.com</p>
    </section>
  );
}

export default About;
